var dropdown = {

    $last: null,

    init: function () {
        dropdown.parse($('body'));
    },

    parse: function (el) {
        $('.dropdown > a', el).each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                if ($(this).parent().hasClass('open')) {
                    dropdown.close();
                } else {
                    dropdown.open($(this).parent());
                }
            });
        });
    },

    open: function ($dropdown) {
        if ($dropdown == dropdown.$last) {
            return false;
        }
        $('#shadow-bg').addClass('display-bloc');
        $dropdown.addClass('open');
        setTimeout(function () {
            dropdown.$last = $dropdown;
            $(document).on('click', dropdown.closeLastOnClick);
        }, 50);
    },

    closeLastOnClick: function (e) {
        if ($(e.target).parents('.dropdown').first() && dropdown.$last && ($(e.target).parents('.dropdown').first()[0] == dropdown.$last[0])) {
            return true;
            $('#shadow-bg').removeClass('display-bloc');
        }
        dropdown.close();
    },

    close: function () {
        dropdown.$last.removeClass('open');
        $('#shadow-bg').removeClass('display-bloc');

        $(document).off('click', dropdown.closeLastOnClick);
        dropdown.$last = null;
        $('#shadow-bg').removeClass('display-bloc');
    }

};