var bloc_video = {

    videoTemplates: {
        'vimeo': '<iframe src="https://player.vimeo.com/video/[VIDEO_ID]?&autoplay=[AUTOPLAY]&background=[MUTE]&dnt=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
        'youtube': '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/[VIDEO_ID]?&autoplay=[AUTOPLAY]&mute=[MUTE]" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    },

    generateVideoIframe: function (videoId, plateforme, mute, autoplay) {
        if (!videoId) {
            return false;
        }
        if (!bloc_video.videoTemplates[plateforme]) {
            return false;
        }
        if (!mute) {
            mute = 0;
        }
        if (!autoplay) {
            autoplay = 0;
        }
        var html = bloc_video.videoTemplates[plateforme] + '';
        html = html.replace('[VIDEO_ID]', videoId);
        html = html.replace('[MUTE]', mute);
        html = html.replace('[AUTOPLAY]', autoplay);
        return html;
    },

    displayVideo: function ($elContainer, $elMask) {

        var videoId = $elContainer.data('video_id');
        var plateforme = $elContainer.data('video_plateforme');

        var mute = 0;
        var autoplay = 0;

        if ($elMask.length > 0) {
            var autoplay = 1;
        }

        var iframeHtml = bloc_video.generateVideoIframe(videoId, plateforme, mute, autoplay);
        $elContainer.append(iframeHtml);
        $elMask.addClass('hide-mask');
    },

    init: function () {
        $('.bloc-video, .video, .sit-video').each(function () {
            var $elMask = $('.mask-video', this);
            var $elContainer = $('.embed-container', this);

            if ($elMask.length > 0) {
                $($elMask).click(function (e) {
                    bloc_video.displayVideo($elContainer, $elMask);
                });
            } else {
                bloc_video.displayVideo($elContainer, $elMask);
            }
        });
    }

};


bloc_video.init();