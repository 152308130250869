if(isMobileOrSmaller()){

    var lastStOverlayScroll = 0;

    overlay.addCallbackOpen(function(overlayId){
        lastStOverlayScroll = $('body').scrollTop();
        $('body').css({'top':-lastStOverlayScroll+'px'}).addClass('fixed');
    });

    overlay.addCallbackClose(function(overlayId){
        $('body').removeClass('fixed').css({'top':0}).scrollTop(lastStOverlayScroll);
    });

}

overlay.addCallbackOpen(function(overlayId){
    $('body').addClass('no-scroll');
});

overlay.addCallbackClose(function(overlayId){
    $('body').removeClass('no-scroll');
});