if (isIE()) {
    document.getElementsByTagName('body')[0].className += ' ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += ' no-hover';
}

dropdown.init();
overlay.init();
Vheight.init();
anchor.parse();

document.addEventListener('orientationchange', function () {
    setTimeout(function () {
        if (isMobileOrSmaller()) {
            thVheight.update('m-vheight');
        } else {
            thVheight.update();
        }
    }, 500);
});


// affichage des overlays possible que a partie de 2s
setTimeout(function () {
    $('body').addClass('overlay-load');
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();

// Lancement du script de ObjectFit
objectFitImages('.fit-cover img');


/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function (e) {
    var st = $(this).scrollTop();

    if (st > 100) {
        $("body").addClass("is-scrolled");
    }
    else {
        $("body").removeClass("is-scrolled");
    }
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});