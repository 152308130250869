$('.slick-carousel').each(function () {

    if ($('> *', this).length > 1) {

        var $slick = $(this);
        var options = {
            dots: false,
            arrows: false,
            prevArrow:"<button type='button' class='slick-prev pull-left'><svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n" +
                "\t viewBox=\"0 0 407.436 407.436\" style=\"enable-background:new 0 0 407.436 407.436;\" xml:space=\"preserve\">\n" +
                "<polygon points=\"112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 \"/>\n" +
                "<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></button>",
            nextArrow:"<button type='button' class='slick-next pull-right'><svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n" +
                "\t viewBox=\"0 0 407.436 407.436\" style=\"enable-background:new 0 0 407.436 407.436;\" xml:space=\"preserve\">\n" +
                "<polygon points=\"112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 \"/>\n" +
                "<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></button>",
            loop: true,
            infinite: true,
            variableWidth: false,
            autoplay: false,
            autoplaySpeed: 0
        };

        if (this.getAttribute('data-params')) {
            options = eval(this.getAttribute('data-params') + '()');
        }

        var possibleOptions = ["accessibility", "adaptiveHeight", "appendArrows", "appendDots", "arrows", "asNavFor", "autoplay", "autoplaySpeed", "centerMode", "centerPadding", "cssEase", "customPaging", "dots", "dotsClass", "draggable", "easing", "edgeFriction", "fade", "focusOnSelect", "infinite", "initialSlide", "lazyLoad", "mobileFirst", "pauseOnHover", "pauseOnFocus", "pauseOnDotsHover", "respondTo", "responsive", "rows", "rtl", "slide", "slidesPerRow", "slidesToShow", "slidesToScroll", "speed", "swipe", "swipeToSlide", "touchMove", "touchThreshold", "useCSS", "useTransform", "variableWidth", "vertical", "verticalSwiping", "waitForAnimate", "zIndex"];
        var allDatas = $slick.data();

        $.each(allDatas, function (key, val) {
            if (possibleOptions.indexOf(key) !== -1) {
                if (key === 'autoplay') {
                    options.autoplay = true;
                    options.autoplaySpeed = val;
                } else {
                    options[key] = val;
                }
            }
        });

        $slick.slick(options);

    }
});