/*global tarteaucitron */
tarteaucitron.lang = {
    "middleBarHead": "☝ 🍪",
    "adblock": "Xin chào! Trang web này minh bạch và cho phép bạn chọn dịch vụ bên thứ 3 mà bạn muốn cho phép.",
    "adblock_call": "Vui lòng vô hiệu hóa trình chặn quảng cáo của bạn để bắt đầu tùy chỉnh.",
    "reload": "Làm mới trang",

    "alertBigScroll": "tiếp tục cuộn,",
    "alertBigClick": "Nếu bạn tiếp tục truy cập trang web này,",
    "alertBig": "bạn đang cho phép tất cả các dịch vụ của bên thứ ba",

    "alertBigPrivacy": "Trang web này sử dụng cookie và cung cấp cho bạn quyền kiểm soát những gì bạn muốn kích hoạt",
    "alertSmall": "Quản lý dịch vụ",
    "acceptAll": "OK, đồng ý",
    "personalize": "Cá nhân",
    "close": "Đóng",

    "privacyUrl": "Chính sách bảo mật",

    "all": "Ưu tiên cho tất cả các dịch vụ",

    "info": "Bảo vệ sự riêng tư của bạn",
    "disclaimer": "Bằng cách cho phép các dịch vụ bên thứ ba này, bạn chấp nhận cookie của họ và sử dụng các công nghệ theo dõi cần thiết cho hoạt động đúng đắn của họ.",
    "allow": "Cho phép",
    "deny": "Từ chối",
    "noCookie": "Dịch vụ này không sử dụng cookie.",
    "useCookie": "Dịch vụ này có thể cài đặt",
    "useCookieCurrent": "Dịch vụ này đã được cài đặt",
    "useNoCookie": "Dịch vụ này không được cài đặt bất cứ cookie nào.",
    "more": "Xem thêm",
    "source": "Xam trang web chính thức",
    "credit": "Cookies được quản lý bằng tarteaucitron.js",
    "noServices": "Trang web này không sử dụng bất kì cookie nào yêu cầu sự chấp thuận của bạn.",

    "toggleInfoBox": "Hiển thị / ẩn thông tin về lưu trữ cookie",
    "title": "Bảng quản lý cookie",
    "cookieDetail": "Cookie chi tiết",
    "ourSite": "trên site của chúng ta",
    "newWindow": "(Cửa sổ mới)",
    "allowAll": "Cho phép tất cả các Cookies",
    "denyAll": "Từ chối cất cả cookies",

    "fallback": "tắt.",

    "ads": {
        "title": "Mạng quảng cáo",
        "details": "Mạng quảng cáo có thể tạo doanh thu bằng cách bán không gian quảng cáo trên trang web."
    },
    "analytic": {
        "title": "Đo lường hành vi người dùng",
        "details": "Công cụ đo lường hành vi người dùng cập nhật những thống kê hữu ích nhằm nâng cao chất lượng phục vụ của website."
    },
    "social": {
        "title": "Các mạng xã hội",
        "details": "Mạng xã hội có thể cải thiện khả năng sử dụng của trang web và giúp quảng bá nó thông qua các chia sẻ."
    },
    "video": {
        "title": "Các video",
        "details": "Dịch vụ chia sẻ video giúp thêm phương tiện phong phú trên trang web và tăng khả năng hiển thị của nó."
    },
    "comment": {
        "title": "Bình luận",
        "details": "Quản lý comments tạo điều kiện cho việc gửi ý kiến và chống thư rác."
    },
    "support": {
        "title": "Hỗ trợ",
        "details": "Các dịch vụ hỗ trợ cho phép bạn liên lạc với nhóm trang web và giúp cải thiện nó."
    },
    "api": {
        "title": "APIs",
        "details": "APIs được sử dụng để load: geolocation, search engines, translations, ..."
    },
    "other": {
        "title": "Dịch vụ khác",
        "details": "Dịch vụ hiển thị nội dung web."
    },
    
    "mandatoryTitle": "Mandatory cookies",
    "mandatoryText": "This site uses cookies necessary for its proper functioning which cannot be deactivated."
};
