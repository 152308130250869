var overlay = {

    selector_overlay: '.overlay:not(.overlay-map)',
    selector_overlay_shadow: '.shadow-overlay',

    callbackOpen: [],
    callbackClose: [],
    hashList: {},

    init: function () {
        overlay.parseOverlayShadow($('body'));
        overlay.parse($('body'));
        overlay.openOnLoad();
    },

    addCallbackOpen: function (callback) {
        overlay.callbackOpen.push(callback);
    },
    addCallbackClose: function (callback) {
        overlay.callbackClose.push(callback);
    },

    parseOverlayShadow: function () {
        $(overlay.selector_overlay_shadow).on('click', function (e) {
            e.preventDefault();
            overlay.close();
        });
    },

    parse: function (el) {

        $(overlay.selector_overlay).each(function () {

            var overlayId = this.id;
            var targetOpen = $('a[href="#' + overlayId + '"]:not(.parsed-overlay):not(.close), [data-overlay-open="' + overlayId + '"]:not(.parsed-overlay)');

            if ($(this).data("hash") !== undefined) {
                overlay.hashList[$(this).attr("data-hash")] = overlayId;
            }

            targetOpen.addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-open');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }
                    overlay.open(idOverlay);
                });

            $('a[href="#' + overlayId + '"].close:not(.parsed-overlay), [data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click', function (e) {

                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-close');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    if (!idOverlay) {
                        idOverlay = null;
                    }
                    e.preventDefault();
                    overlay.close(idOverlay);
                });
        });
    },

    openOnLoad: function () {

        var current_hash = (window.location.hash).split('#');
        var founded_overlay = false;
        if (current_hash) {
            current_hash.forEach(function (element) {
                if (typeof overlay.hashList[element] !== 'undefined' && !founded_overlay) {
                    overlay.open(overlay.hashList[element]);
                    founded_overlay = true;
                }
            })
        }
    },

    open: function (overlayId, openShadow, doCallback) {
        if (openShadow !== false) {
            openShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }


        $(overlay.selector_overlay + ".open").each(function (e) {
            overlay.close($(this).attr('id'), false, true)
        });

        $('#' + overlayId).addClass('open');

        if ($('#' + overlayId + '').data('hash') !== undefined) {
            window.location.hash = $('#' + overlayId + '').data('hash');
        }

        if (openShadow == true) {
            $(overlay.selector_overlay_shadow).addClass('open');
        }

        if (doCallback == true) {
            $.each(overlay.callbackOpen, function (k, callback) {
                callback(overlayId);
            });
        }
    },

    close: function (overlayId, closeShadow, doCallback) {

        if (closeShadow !== false) {
            closeShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        window.location.hash = "";
        history.replaceState(null, null, ' ');


        if (overlayId) {
            $('#' + overlayId).removeClass('open');
            if ($(overlay.selector_overlay + '.open').length == 0 && closeShadow) {
                $(overlay.selector_overlay_shadow).removeClass('open');
            }
        } else {
            $(overlay.selector_overlay + '.open').removeClass('open');
            if (closeShadow) {
                $(overlay.selector_overlay_shadow).removeClass('open');
            }
        }

        if (doCallback) {
            $.each(overlay.callbackClose, function (k, callback) {
                callback(overlayId);
            });
        }

    }

};