// Ouverture du menu mobile
$('#menuToggle').click(function () {
    if (!$(this).hasClass('open')) {
        $(this).addClass('open');
        $('#header').addClass('menu-open');
        $('.shadow-overlay').addClass('open');
    }
    else {
        $(this).removeClass('open');
        $('#header').removeClass('menu-open');
        $('.shadow-overlay').removeClass('open');
    }
});


$('.shadow-overlay').on('click',function(){
    $('#header').removeClass('menu-open');
    $(this).removeClass('open');
    $('#menuToggle').removeClass('open');
    $('#menuToggle input').prop('checked',false);
});



// Ouverture - Fermeture de la DropDown Menu en accordéon
var $lienNav = $('.wrapper-nav nav > ul > li.menu-item-has-children > a');
$lienNav.each(function(){
    $(this).on('click',function(e){
        e.preventDefault();
        var $otherSubOpened = $('.wrapper-nav nav > ul > li.menu-item-has-children.sub-menu-open').first();
        var $parent = $(this).parents('.menu-item-has-children').first();
        if($otherSubOpened[0] != $parent[0]){
            if($otherSubOpened){
                $otherSubOpened.removeClass('sub-menu-open');
            }
            setTimeout(function(){
                $parent.addClass('sub-menu-open');
            },50);
        }else{
            $otherSubOpened.removeClass('sub-menu-open');
            $otherSubOpened = null;
        }
    }  );
});


if($('.header-no-image').length > 0){
    $('#header').addClass('header-color');
}