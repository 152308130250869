function isIE(){
    if (navigator.userAgent.match(/trident/gi) || navigator.appName == 'Microsoft Internet Explorer') {
        return true;
    }
    return false;
}

function isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
}

function isNoHover(){
    if(isTouchDevice() && document.body.clientWidth <= Config.tabletPaysageBreakpoint){
        return true;
    }
    return false;
}

function isTabletPortraitOrSmalller(){
    if(document.body.clientWidth < Config.tabletPortraitBreakpoint){
        return true;
    }
    return false;
}

function isMobileOrSmaller(){
    if(document.body.clientWidth < Config.mobileBreakpoint){
        return true;
    }
    return false;
}

if (isIE()) {
    document.getElementsByTagName('body')[0].className += 'ie';
}

if (isNoHover()) {
    document.getElementsByTagName('body')[0].className += 'no-hover';
}


function hasIscroll() {
    if(navigator.userAgent.match(/Android/i)){
        return false;
    }
    if(navigator.userAgent.match(/iP(hone|od|ad)/)){
        return false;
    }
    if(document.body.clientWidth <= 1024) {
        return false;
    }
    return true;
}